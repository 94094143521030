import styled, { CSSProperties } from 'styled-components';
import { Z_INDEX_LAYERS } from '../../../componentUI';
import { truthfulAdaptive } from '../../../componentUI/screen';
import { TEXT_WEIGHT } from '../../../componentUI/simple/Text';

const RefundPolicyBannerStyled = styled.div`
  position: relative;

  margin: 24px 40px 0 40px;
  padding: 0 20px;

  display: flex;
  align-items: center;
  gap: 12px;

  border-radius: 10px;
  background: linear-gradient(90deg, #2a2d38 0%, #3d2a30 100%);

  ${truthfulAdaptive.maxWidth.desktopS} {
    margin: 16px 16px 0 16px;
  }

  ${truthfulAdaptive.maxWidth.mobile} {
    padding: 16px;
    flex-direction: column;
    gap: 8px;
  }
`;

const ContainerStyled = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;

  ${truthfulAdaptive.maxWidth.mobile} {
    flex-direction: column;
    gap: 8px;
  }
`;

const WrapperTextStyled = styled.div`
  display: flex;
  align-items: center;
`;

const stylesForDarkButton: CSSProperties = {
  position: 'absolute',
  top: '8px',
  right: '8px',
  zIndex: `${Z_INDEX_LAYERS.other}`,
};

export const stylesForTertiaryButton: CSSProperties = {
  padding: '10px 24px',
  backgroundColor: 'transparent',
  whiteSpace: 'nowrap',
  textTransform: 'uppercase',
  fontWeight: TEXT_WEIGHT.bold,
};

export { ContainerStyled, RefundPolicyBannerStyled, stylesForDarkButton, WrapperTextStyled };
