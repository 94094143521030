import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotificationQuery } from '../../api/notifications';
import { UsersIcon } from '../../common/icons/SvgInline/SvgIcons_10';
import { BUTTON_SIZE, SecondButton } from '../../componentUI/simple/Button';
import { getFilteredNotifications } from '../../helpers';
import { BellNotification } from '../BellNotification';
import { BreadCrumbs } from '../BreadCrumbs';
import { ButtonContainer, ControlsBlockContainer, RowControlBlock, WrapperSecondButton } from './styled';

const isHidden = true;

export const ControlsBlock = ({ isSummaryBlock }: { isSummaryBlock?: boolean | undefined }) => {
  const { t } = useTranslation();

  const [hasNotification, setHasNotification] = useState(false);
  const { data } = useNotificationQuery();
  const products = getFilteredNotifications(data?.notifications);

  useEffect(() => {
    if (products?.length) {
      setHasNotification(true);
    } else {
      setHasNotification(false);
    }
  }, [products]);

  return (
    <ControlsBlockContainer isSummaryBlock={isSummaryBlock}>
      <BreadCrumbs />
      <RowControlBlock>
        {!isHidden && (
          <WrapperSecondButton>
            <SecondButton
              onClick={() => {
                window.open('https://b2b.legitgrails.com/platform-demo-call', '_blank', 'noopener,noreferrer');
              }}
              size={BUTTON_SIZE.extraSmall}
              isStretch
            >
              <ButtonContainer>
                <UsersIcon />
                {t('controlsBlockTitle')}
              </ButtonContainer>
            </SecondButton>
          </WrapperSecondButton>
        )}

        <BellNotification hasNotification={hasNotification} />
      </RowControlBlock>
    </ControlsBlockContainer>
  );
};
