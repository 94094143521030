import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../../../common/icons/SvgInline/SvgIcons_2';
import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { Z_INDEX_LAYERS } from '../../../componentUI/constants';
import { DarkButton, TertiaryButton } from '../../../componentUI/simple/Button';
import { Text, TEXT_WEIGHT } from '../../../componentUI/simple/Text';
import { useMatchMedia, useStyledModal } from '../../../hooks';
import { BellBannerImage } from './assets';
import { RefundPolicyModalContent } from './components';
import {
  ContainerStyled,
  RefundPolicyBannerStyled,
  stylesForDarkButton,
  stylesForTertiaryButton,
  WrapperTextStyled,
} from './styled';

const REFUND_POLICY_BANNER_KEY = 'RefundPolicyBannerHidden';

const COLOR_MAP_BTN = {
  clickable: COLOR_MAP.controls.light.clickable,
  clickableHover: COLOR_MAP.controls.light.clickableHover,
  clickablePressed: COLOR_MAP.controls.light.clickablePressed,
  clickableDisabled: COLOR_MAP.controls.light.clickableDisabled,
};

export const RefundPolicyBanner = () => {
  const { t } = useTranslation();
  const { isMobile } = useMatchMedia();
  const [Modal, toggleModal] = useStyledModal();

  const isHiddenWelcomeBannerNewUser = Boolean(localStorage.getItem(REFUND_POLICY_BANNER_KEY));

  const [isHiddenBanner, setIsHiddenBanner] = useState(isHiddenWelcomeBannerNewUser || false);

  if (isHiddenBanner) {
    return null;
  }

  return (
    <RefundPolicyBannerStyled>
      <DarkButton
        colorsMap={COLOR_MAP_BTN}
        style={stylesForDarkButton}
        onClick={() => {
          setIsHiddenBanner(true);
        }}
      >
        <CloseIcon size="20px" color={COLOR_MAP.accent.white} />
      </DarkButton>
      <img width="60px" height="60px" src={BellBannerImage} alt="BellBanner" />
      <ContainerStyled>
        <WrapperTextStyled>
          <Text
            weight={TEXT_WEIGHT.bold}
            style={{ zIndex: `${Z_INDEX_LAYERS.modalOnboarding}`, ...(isMobile && { textAlign: 'center' }) }}
            colorText={COLOR_MAP.text.white}
          >
            {t('refundPolicyBanner.title')}
          </Text>
        </WrapperTextStyled>

        <TertiaryButton onClick={toggleModal} colorsMap={COLOR_MAP_BTN} style={stylesForTertiaryButton}>
          {t('refundPolicyBanner.btnText')}
        </TertiaryButton>
      </ContainerStyled>

      <Modal
        isError
        stylesForModal={{ maxWidth: '670px', ...(isMobile && { padding: '26px 20px' }) }}
        onClose={() => {
          setIsHiddenBanner(true);
          localStorage.setItem(REFUND_POLICY_BANNER_KEY, 'true');
        }}
      >
        <RefundPolicyModalContent />
      </Modal>
    </RefundPolicyBannerStyled>
  );
};
