/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAccountInfoQuery } from '../../api/auth/hooks';
import { useMatchMedia, useOnboardingModal } from '../../hooks';
import { ColumnMenu } from '../../modules/ColumnMenu/containers';
import { HeaderRedesign } from '../../modules/HeaderRedisign';
import { RefundPolicyBanner, WelcomeBannerNewUser } from '../Banners';
import { BreadCrumbs } from '../BreadCrumbs';
import { ControlsBlock } from '../ControlsBlock';
import { OnboardingSlider } from '../OnboardingSlider';
import {
  BaseContainer,
  BreadCrumbsWrapper,
  ContentContainer,
  GlobalStyleFromPage,
  MainContainer,
  Overlay,
} from './styled';
import { Props } from './types';

/** Содержит боковое меню и шапку c нотификациями и логаутом
 * на >900px уходит боковое меню и отображается шапка  т.к. таблицы корректно не помещаются по ширине
 * на >600px переходит в мобильную версию
 */

const OVERLAYING_MODAL_KEY = 'OverlayingModalHidden';

export const BaseLayout = ({ children, isSummaryBlock, isNotDvh }: Props) => {
  const { pathname } = useLocation();
  const { isDesktopS } = useMatchMedia();
  const [OverlayingModal, toggleOverlayingModal] = useOnboardingModal();

  const [showColumnMobileMenu, setShowColumnMobileMenu] = useState(false);

  const { data: accountData, isLoading: isLoadingAccountData } = useAccountInfoQuery(null, true);

  // это новый пользователь у него ещё нет заказов
  const isNewAccount = accountData?.isNewUserAccount && !isLoadingAccountData;

  const isOldAccount = accountData?.isOldPortalUser && !isLoadingAccountData;

  // это страница "Дашборд"
  const isDashboard = pathname.includes('dashboard');

  const OverlayingModalHasBeenViewed = localStorage.getItem(OVERLAYING_MODAL_KEY);

  useEffect(() => {
    if (isNewAccount && !OverlayingModalHasBeenViewed) {
      toggleOverlayingModal();
      localStorage.setItem(OVERLAYING_MODAL_KEY, 'true');
    }
  }, [isNewAccount]);

  // Close the mobile menu when the pathname changes
  useEffect(() => {
    if (showColumnMobileMenu) {
      setShowColumnMobileMenu(false);
    }
  }, [pathname]);

  return (
    <BaseContainer>
      {!isDesktopS && <ColumnMenu />}

      {isDesktopS && (
        <HeaderRedesign setShowColumnMobileMenu={setShowColumnMobileMenu} showColumnMobileMenu={showColumnMobileMenu} />
      )}

      <MainContainer>
        {isOldAccount && <RefundPolicyBanner />}

        {isNewAccount && isDashboard && <WelcomeBannerNewUser />}

        {isDesktopS && !isSummaryBlock && !isDashboard && (
          <BreadCrumbsWrapper isSummaryBlock={isSummaryBlock}>
            <BreadCrumbs />
          </BreadCrumbsWrapper>
        )}

        {!isDesktopS && !isSummaryBlock && <ControlsBlock />}

        <ContentContainer isSummaryBlock={isSummaryBlock} isNotDvh={isNotDvh}>
          {children}
        </ContentContainer>
      </MainContainer>

      <Overlay
        isColumnMenuOpen={showColumnMobileMenu}
        onClick={(event) => {
          if (event.target === event.currentTarget) {
            setShowColumnMobileMenu(false);
          }
        }}
      >
        <ColumnMenu isSidebar closeHandler={() => setShowColumnMobileMenu(false)} />
      </Overlay>

      {showColumnMobileMenu && <GlobalStyleFromPage />}

      <OverlayingModal onClose={toggleOverlayingModal}>
        <OnboardingSlider onClose={toggleOverlayingModal} />
      </OverlayingModal>
    </BaseContainer>
  );
};
