import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, TEXT_SIZE, TEXT_WEIGHT } from '../../../../componentUI/simple/Text';
import { MainBlockStyled, RefundPolicyModalContentStyled } from './styled';

export const RefundPolicyModalContent = () => {
  const { t } = useTranslation();

  return (
    <RefundPolicyModalContentStyled>
      <Text>{t('refundPolicyBanner.modalRefundPolicyBanner.textHeader')}</Text>

      <MainBlockStyled>
        <Text size={TEXT_SIZE.sub} weight={TEXT_WEIGHT.bold}>
          {t('refundPolicyBanner.modalRefundPolicyBanner.mainTitleText')}
        </Text>
        <Text size={TEXT_SIZE.sub}>{t('refundPolicyBanner.modalRefundPolicyBanner.mainText1')}</Text>
        <Text size={TEXT_SIZE.sub}>{t('refundPolicyBanner.modalRefundPolicyBanner.mainText2')}</Text>
      </MainBlockStyled>

      <Text>{t('refundPolicyBanner.modalRefundPolicyBanner.textFooter')}</Text>
    </RefundPolicyModalContentStyled>
  );
};
