import styled from 'styled-components';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';

const RefundPolicyModalContentStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const MainBlockStyled = styled.main`
  padding: 8px 12px;

  display: flex;
  flex-direction: column;
  gap: 6px;

  border-radius: 4px;
  background: ${COLOR_MAP.background.brandColor10};
`;

export { MainBlockStyled, RefundPolicyModalContentStyled };
